import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import marked from 'marked'

import App from '../components/App'

import { spacing } from '../utils/styles'
import terms from '../../data/terms.md'

marked.setOptions({
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
})

class Template extends React.Component {
  render() {
    const html = marked(terms)
    const siteTitle = 'Termeni si conditii'

    return (
      <App style={{ background: '#fff' }}>
        <Helmet title={siteTitle} />

        <div className="wrapper sectionContainer">
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="markdown"
          />
        </div>
        <style jsx>{`
          .wrapper {
            padding-top: 120px;
          }
          .section-headline {
            margin-bottom: 40px;
          }
          :global(.markdown > *) {
            margin-top: ${spacing.md}px;
          }
        `}</style>
      </App>
    )
  }
}

export default Template
